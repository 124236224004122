<template>

  <div>
    <div>

      <b-card
        no-body
        class="mb-0"
      >

        <b-table
          ref="refMaterialListTable"
          class="position-relative"
          responsive
          :fields="tableColumns"
          :items="supplierCompetencies"
          style="min-height: 140px;"
        >
          <!-- Column: Core Competencies -->
          <template #cell(core_competencies)="data">
            <div class="text-nowrap">
              {{ data.item.label }}
            </div>
          </template>

          <!-- Column: Categories -->
          <template #cell(categories)="data">
            <div class="d-flex flex-column">
              <div
                v-for="category in data.item.categories"
                :key="category"
                class="font-weight-bold"
              >{{ category }}</div>
            </div>
          </template>

          <template #cell(services)="data">
            <div class="d-flex flex-column">
              <div
                v-for="(service, index_service) in data.item.services"
                :key="index_service"
              ><b-badge
                v-for="(item, index_item) in service"
                :key="index_item"
                pill
                variant="secondary"
                style="padding: 10px; margin-top: 4px; margin-right: 3px;"
              >{{ item }}</b-badge>
              </div>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              :disabled="!canEditSupplier"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'apps-suppliers-edit-core',
                       params: { id: currentId, core: data.item.key } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BBadge, BCard, BDropdown, BDropdownItem, BTable,
} from 'bootstrap-vue'
import {
  computed, onUnmounted,
} from '@vue/composition-api'
import router from '@/router'

import supplierStoreModule from '../supplierStoreModule'

export default {
  components: {
    BBadge,
    BCard,
    BDropdown,
    BDropdownItem,
    BTable,
  },
  props: {
    supplierData: {
      type: Object,
      required: true,
    },
    canEditSupplier: {
      type: Boolean,
      required: true,
    },
    coreCompetenciesLabels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  setup(props, { root }) {
    const SUPPLIER_APP_STORE_MODULE_NAME = 'app-supplier'

    // Register module
    if (!root.$store.hasModule(SUPPLIER_APP_STORE_MODULE_NAME)) {
      root.$store.registerModule(SUPPLIER_APP_STORE_MODULE_NAME, supplierStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (root.$store.hasModule(SUPPLIER_APP_STORE_MODULE_NAME)) {
        root.$store.unregisterModule(SUPPLIER_APP_STORE_MODULE_NAME)
      }
    })

    const tableColumns = computed(() => (props.canEditSupplier ? [
      'core_competencies', 'categories', 'services', 'actions'] : [
      'core_competencies', 'categories', 'services']))

    const currentId = computed(() => router.currentRoute.params.id)

    const supplierCompetencies = computed(() => {
      const core = []
      props.coreCompetenciesLabels.forEach(item => {
        let categories = []
        let services = []
        const data = props.supplierData[item.key]
        if (data) {
          categories = Object.keys(data)
          services = Object.values(data)
        }
        core.push({
          label: item.label,
          key: item.key,
          categories,
          services,
        })
      })
      return core
    })
    return {
      currentId,
      tableColumns,
      supplierCompetencies,
    }
  },
}
</script>
