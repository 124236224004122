<template>
  <section class="suppliers-edit-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="supplierData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching supplier data
      </h4>
      <div class="alert-body">
        No supplier found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-suppliers-list'}"
        >
          Suppliers
        </b-link>
        list.
      </div>
    </b-alert>

    <b-row v-if="supplierData">
      <b-col
        cols="12"
      >
        <b-button
          variant="outline-primary"
          size="md"
          class="mb-2"
          @click="goBack"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            size="16"
            class="mr-2 mr-sm-50"
          />Back
        </b-button>

        <b-card>
          <supplier-header
            :supplier-data="supplierData"
            :can-edit-status="canEditStatus"
            :can-edit-supplier="canEditSupplier"
            :workflow-status-options="workflowStatusOptions"
            :core-competencies-labels="coreCompetenciesLabels"
            @set-supplier-status="setSupplierStatus"
            @set-supplier-image="setSupplierImage"
          />
          <hr class="divider mb-2">

          <b-tabs
            v-model="tabIndex"
            pills
          >
            <!-- Tab: Main Info -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="InfoIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Main Info</span>
              </template>
              <hr class="divider mb-2">
              <supplier-edit-tab-basic
                :supplier-data="supplierData"
                :can-edit-status="canEditStatus"
                :can-edit-supplier="canEditSupplier"
                :workflow-status-options="workflowStatusOptions"
                :type-options="typeOptions"
                class="mt-2 pt-75"
                @fetch-supplier="fetchSupplier"
              />
            </b-tab>

            <!-- Tab: Core Competencies -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="StarIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Core Competencies</span>
              </template>
              <hr class="divider mb-2">
              <supplier-edit-tab-core
                :supplier-data="supplierData"
                :can-edit-supplier="canEditSupplier"
                :core-competencies-labels="coreCompetenciesLabels"
                class="mt-2 pt-75"
                @fetch-supplier="fetchSupplier"
              />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-if="supplierData"
      v-model="showDeleteModal"
      ok-title="Remove"
      title="Remove Supplier"
      @ok="removeSupplier"
    >
      Are you sure that you want to remove
      <b>{{ supplierData.name }}</b>?
    </b-modal>
  </section>
</template>

<script>
import {
  BAlert, BButton, BCard, BCol, BLink, BModal, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import {
  computed, ref, onMounted, onUnmounted, watch,
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import supplierStoreModule from '../supplierStoreModule'
import SupplierEditTabBasic from './SupplierEditTabBasic.vue'
import SupplierEditTabCore from './SupplierEditTabCore.vue'
import SupplierHeader from './SupplierHeader.vue'

export default {
  components: {
    BAlert,
    BButton,
    BCard,
    BCol,
    BLink,
    BModal,
    BRow,
    BTab,
    BTabs,

    SupplierHeader,
    SupplierEditTabBasic,
    SupplierEditTabCore,
  },
  setup(props, { root }) {
    const toast = useToast()

    const supplierData = ref(null)
    const canEditStatus = ref(true)
    const canEditSupplier = ref(true)

    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const canDeleteSupplier = ref(false)
    const showDeleteModal = ref(false)

    const SUPPLIER_APP_STORE_MODULE_NAME = 'app-supplier'

    // Register module
    if (!store.hasModule(SUPPLIER_APP_STORE_MODULE_NAME)) {
      store.registerModule(SUPPLIER_APP_STORE_MODULE_NAME, supplierStoreModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUPPLIER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(SUPPLIER_APP_STORE_MODULE_NAME)
      }
    })

    const coreCompetenciesLabels = [
      { key: 'designer_services', label: 'Designer Services' },
      { key: 'garment_manufacturing_techniques', label: 'Garment Manufacturing Techniques' },
      { key: 'fabrics_specialties', label: 'Fabrics Specialties' },
      { key: 'trims_accessories', label: 'Trims/Accessories' },
      { key: 'printing_embroidery_techniques', label: 'Printing/Embroidery Techniques' },
      { key: 'label_services', label: 'Label Services' },
      { key: 'packaging_services', label: 'Packaging Services' },
    ]

    const tabIndex = ref(
      router.currentRoute.hash ? parseInt(router.currentRoute.hash.slice(1), 10) : 0,
    )

    watch([tabIndex], () => {
      router.replace({
        name: router.currentRoute.name,
        query: router.currentRoute.query,
        hash: `#${tabIndex.value}`,
      })
    })

    const workflowStatusOptions = computed(() => {
      if (userRole === 'platform-manager') {
        return [
          { label: 'Draft', value: 'Draft' },
          { label: 'Pending', value: 'Pending' },
          { label: 'Approved', value: 'Approved' },
          { label: 'Removed', value: 'Removed' },
        ]
      }
      if (supplierData) {
        if (supplierData.value.status === 'Draft') {
          return [
            { label: 'Send for Approval', value: 'Pending' },
          ]
        }
        if (supplierData.value.status === 'Pending') {
          return [
            { label: 'Draft', value: 'Draft' },
          ]
        }
      }
      return []
    })

    const typeOptions = computed(() => {
      const options = root.$store.getters['app-supplier/typeOptions']
      return options
    })

    const optionsInit = () => {
      root.$store.dispatch('app-supplier/fetchFormOptions')
    }

    const fetchSupplier = () => {
      store.dispatch('app-supplier/fetchSupplier', { id: router.currentRoute.params.id })
        .then(response => {
          supplierData.value = response.data
          if (['Removed', 'Approved'].includes(supplierData.value.status)) {
            canEditSupplier.value = userRole === 'platform-manager'
            canEditStatus.value = userRole === 'platform-manager'
          } else {
            canEditSupplier.value = true
            canEditStatus.value = true
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            supplierData.value = undefined
          }
        })
    }

    const removeSupplier = () => {
      store.dispatch('app-supplier/removeSupplier', {
        id: router.currentRoute.params.id,
      })
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Supplier removed',
              icon: 'Trash2Icon',
              variant: 'danger',
            },
          }).then(router.push({ name: 'apps-suppliers-list' }))
        })
    }

    const setSupplierStatus = status => {
      store.dispatch('app-supplier/editSupplier', {
        id: router.currentRoute.params.id,
        status,
      })
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Supplier ${supplierData.value.name} Status modified`,
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        }).then(fetchSupplier)
    }

    const setSupplierImage = formData => {
      store.dispatch('app-supplier/uploadSupplierImage', {
        id: router.currentRoute.params.id,
        formData,
      })
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Supplier ${supplierData.value.name} Image modified`,
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        }).then(fetchSupplier)
    }

    const goBack = () => {
      router.go(-1)
    }

    onMounted(optionsInit)
    onMounted(fetchSupplier)

    return {
      coreCompetenciesLabels,
      supplierData,
      userRole,
      tabIndex,
      canEditSupplier,
      canEditStatus,
      canDeleteSupplier,
      showDeleteModal,
      fetchSupplier,
      setSupplierStatus,
      setSupplierImage,
      removeSupplier,
      goBack,

      typeOptions,

      workflowStatusOptions,
    }
  },
}
</script>
