<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="d-flex mt-1 mb-1 justify-content-start">
        <div class="mr-5">
          <b-media
            left-align
            vertical-align="center"
          >
            <template #aside>
              <div
                class="logo-image-container"
              >
                <b-img
                  rounded
                  height="64"
                  class="logo-image"
                  :blank="!supplierData.image"
                  blank-color="#f2f2f2"
                  :src="supplierData.image"
                />
                <b-button
                  v-if="canEditSupplier"
                  class="logo-image-dropdown"
                  pill
                  size="sm"
                  @click="$refs.refInputEl.$el.click()"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="14"
                    class="align-middle text-body"
                    :style="{ stroke: 'white' }"
                  />
                </b-button>
              </div>
            </template>
            <h4 class="card-title mt-0 mb-1">
              {{ supplierData.name }}
            </h4>
            <h6 class="card-subtitle text-muted mb-1">
              {{ supplierData.type }}
            </h6>
          </b-media>
        </div>
        <div class="mr-5">
          <h5 class="mt-0 mb-1 text-muted">
            Sourcing
          </h5>
          <h6 class="card-subtitle ">
            <div
              v-for="(item, index) in coreCompetenciesLabels"
              :key="index"
            >
              <div
                v-if="showCompetencyLabel(supplierData[item.key])"
              >{{ item.label }}</div>
            </div>
          </h6>
        </div>
        <div>
          <h5 class="mt-0 mb-1 text-muted">
            Status
          </h5>
          <h6 class="card-subtitle ">
            <template
              v-if="!canEditStatus || workflowStatusOptions.length === 0"
            >
              {{ supplierData.status }}
            </template>
            <b-dropdown
              v-else
              dropright
              :text="supplierData.status"
            >
              <b-dropdown-item
                v-for="option in workflowStatusOptions"
                :key="option.value"
                @click="setSupplierStatus(option.value)"
              >
                <span class="align-middle ml-50">{{ option.label }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </h6>
        </div>
        <b-form-file
          ref="refInputEl"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageUpload"
        />
      </div>
    </div>
    <b-alert
      :show="canEditStatus && supplierData.status === 'Draft'"
      dismissible
      variant="warning"
      class="p-2 mx-0"
    >
      Please ensure you have added the supplier information and core competencies.<br>
      Then, <b>change the status</b> and submit it for approval. <br>
      This will allow Ave Valley to review the information and publish it on the supply chain
      database, enabling you to reference it in your sourcing details.
    </b-alert>
  </div>
</template>

<script>
import {
  BAlert, BButton, BDropdown, BDropdownItem, BFormFile, BImg, BMedia,
} from 'bootstrap-vue'

export default {
  components: {
    BAlert,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BImg,
    BMedia,
  },
  props: {
    supplierData: {
      type: Object,
      required: true,
    },
    canEditStatus: {
      type: Boolean,
      required: true,
    },
    canEditSupplier: {
      type: Boolean,
      required: true,
    },
    workflowStatusOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    coreCompetenciesLabels: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const showCompetencyLabel = data => Object.keys(data).length !== 0

    const setSupplierStatus = status => {
      emit('set-supplier-status', status)
    }

    const inputImageUpload = inputEl => {
      if (inputEl) {
        const formData = new FormData()
        formData.append('image', inputEl)
        emit('set-supplier-image', formData)
      }
    }

    return {
      showCompetencyLabel,
      setSupplierStatus,
      inputImageUpload,
    }
  },
}
</script>
<style lang="scss" scoped>
.logo-image-container {
  position: relative;
}
.logo-image-dropdown {
  position: absolute;
  top: 2px;
  right: 2px;
  display: none;
}
.logo-image-container:hover .logo-image-dropdown {
 display: block;
}
.logo-image-container:hover img {
 filter: brightness(50%);
}
</style>
