<template>
  <div>
    <validation-observer
      ref="refFormObserver"
      #default="{invalid}"
    >
      <!-- Supplier Info: Input Fields -->
      <b-form
        @submit.prevent="onSubmit"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Field: Name -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="Name *"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="formData.name"
                  :state="getValidationState(validationContext)"
                  :maxlength="50"
                  trim
                  :disabled="!canEditSupplier"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Status -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Status"
              label-for="supplier-status"
            >
              <v-select
                v-model="formData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="workflowStatusOptions"
                :reduce="val => val.value"
                :clearable="false"
                :disabled="!canEditSupplier"
                input-id="supplier-status"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Type -->
          <b-col
            cols="12"
            md="8"
          >
            <validation-provider
              #default="validationContext"
              name="Type"
              rules="required"
            >
              <b-form-group
                label="Type *"
                label-for="supplier-type"
              >
                <v-select
                  v-model="formData.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="supplier-type"
                  :disabled="!canEditSupplier"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Street Address"
              rules="required"
            >
              <b-form-group
                label="Street Address *"
                label-for="street-address"
              >
                <vue-google-autocomplete
                  id="street-address"
                  ref="address"
                  v-model="formData.street_address"
                  country="pt"
                  :disabled="!canEditSupplier"
                  placeholder=""
                  class="form-control"
                  @placechanged="getAddressData"
                  @change="onChange"
                />
                <b-form-input
                  id="street-address-2"
                  v-model="formData.street_address_2"
                  trim
                  :disabled="!canEditSupplier"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Zip/Postal code"
              label-for="zip-code"
            >
              <b-form-input
                id="zip-code"
                v-model="formData.zip_code"
                :maxlength="10"
                trim
                :disabled="!canEditSupplier"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Location"
              label-for="location"
            >
              <b-form-input
                id="location"
                v-model="formData.location"
                :maxlength="50"
                trim
                :disabled="!canEditSupplier"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Country"
              label-for="country"
            >
              <b-form-input
                id="country"
                v-model="formData.country"
                :maxlength="50"
                trim
                :disabled="!canEditSupplier"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Website"
              label-for="website"
            >
              <b-form-input
                id="website"
                v-model="formData.website"
                :maxlength="50"
                type="url"
                trim
                :disabled="!canEditSupplier"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid || !canEditSupplier"
        >
          Save Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="!canEditSupplier"
        >
          Reset
        </b-button>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import store from '@/store'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    vSelect,
    VueGoogleAutocomplete,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    supplierData: {
      type: Object,
      required: true,
    },
    canEditStatus: {
      type: Boolean,
      required: true,
    },
    canEditSupplier: {
      type: Boolean,
      required: true,
    },
    workflowStatusOptions: {
      type: Array,
      required: true,
    },
    typeOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit, refs }) {
    const toast = useToast()

    const formData = ref({ ...props.supplierData })
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const isBrand = userRole === 'brand-manager' || userRole === 'brand'
    const isManufacturer = userRole === 'manufacturer-manager' || userRole === 'manufacturer'
    const isAgent = userRole === 'agent-manager' || userRole === 'agent'

    watch(() => props.supplierData.status, newValue => {
      if (newValue !== undefined) {
        formData.value.status = newValue
      }
    }, { deep: true, immediate: false })

    const selectedPlace = ref({})
    const getAddressData = addressData => {
      selectedPlace.value = { ...addressData }
      if (addressData.locality) {
        formData.value.location = addressData.locality
      }
      if (addressData.country) {
        formData.value.country = addressData.country
      }
      if (addressData.postal_code) {
        formData.value.zip_code = addressData.postal_code
      }
    }

    const onChange = text => {
      if (!text) {
        formData.value.street_address = ''
        return
      }
      let streetAddress = ''
      if (selectedPlace.value && selectedPlace.value.route) {
        streetAddress = selectedPlace.value.route
        if (selectedPlace.value.street_number) {
          streetAddress += `, ${selectedPlace.value.street_number}`
        }
      } else {
        streetAddress = text
      }
      formData.value.street_address = streetAddress
      refs.address.update(streetAddress)
      selectedPlace.value = {}
    }

    const onSubmit = () => {
      const supplierData = {
        id: router.currentRoute.params.id,
        ...formData.value,
      }
      store.dispatch('app-supplier/editSupplier', supplierData)
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Supplier edited',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          emit('fetch-supplier')
        })
    }

    const resetFormData = () => {
      formData.value = { ...props.supplierData }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    return {
      onSubmit,
      formData,
      isBrand,
      isManufacturer,
      isAgent,

      refFormObserver,
      getValidationState,
      resetForm,
      getAddressData,
      onChange,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
